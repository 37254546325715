.how-to-overlay {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  align-items: flex-end;
  justify-content: center;
  transition: 0.2s all ease-in-out;
  visibility: hidden;
}

.animated-modal {
  background-color: rgba(35, 12, 15, 0.64);
  visibility: visible;
}

.how-to-container {
  background-color: var(--app-brown);
  overflow: hidden;
  width: 100%;
  height: 0;
  position: relative;
  box-shadow: 4px 4px 12px 4px rgba(218, 165, 32, 0.18),
    -4px -4px 12px 4px rgba(218, 165, 32, 0.18);
  transition: 0.3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.how-to-overlay.animated-modal .how-to-container {
  height: 90%;
}

.how-to-play {
  padding: 0.25rem 1rem;
  height: calc(100% - 1.5rem - 2rem);
  overflow: auto;
}

.how-to-title {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: bold;
  padding: 1rem;
}

.how-to-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.75rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.how-to-close:hover {
  color: darkgoldenrod;
  transform: scale(1.05);
}

@media screen and (min-width: 768px) {
  .how-to-title {
    font-size: 2rem;
  }

  .how-to-play {
    height: calc(100% - 2rem - 2rem);
  }
}

@media screen and (min-width: 576px) {
  .how-to-container {
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .how-to-container {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .how-to-container {
    width: 50%;
  }
}
