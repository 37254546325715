.sign-in-container {
  display: flex;
  height: calc(100vh - 52px);
  justify-content: center;
  color: goldenrod;
  align-items: center;
  flex-direction: column;
}

.sign-in-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  max-width: 32rem;
  text-align: center;
}

.sign-in-section h1 {
  margin: 0;
}

.sign-in-section p {
  line-height: 1.4;
  margin: 0;
}

.sign-in-error {
  color: var(--app-red);
  margin-top: 0.5rem;
}

.button-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sign-in-container button {
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  font-weight: 700;
  padding: 0.75rem 1rem;
  min-width: 18rem;
  border: 2px solid goldenrod;
  border-radius: 1.5rem;
  color: goldenrod;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1;
  transition: 0.2s all ease-in-out;
}

.sign-in-container button:hover {
  transform: scale(1.02);
  color: darkgoldenrod;
  border-color: darkgoldenrod;
}
