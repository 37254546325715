.games-container {
  display: flex;
  flex-direction: column;
  color: goldenrod;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
}

.solved-icon {
  color: var(--app-green);
  margin-right: 0.25rem;
}

.unsolved-icon {
  color: var(--app-red);
  margin-right: 0.25rem;
}

.games-title {
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0 1rem 0.5rem;
}

.game-card {
  background-color: rgba(192, 192, 192, 0.18);
  padding: 1rem;
  border-radius: 0.75rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .game-card,
  .trophy-container {
    max-width: 32rem;
  }
}

.game-card__details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
}

.game-card__title {
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.game-card__title:hover {
  color: darkgoldenrod;
}

.game-card__button {
  color: goldenrod;
  font-size: 3rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.trophy {
  font-size: 2rem;
}

.certificate {
  color: whitesmoke;
}

.trophy-gold {
  color: gold;
}

.trophy-silver {
  color: silver;
}

.trophy-bronze {
  color: #cd7f32;
}

.trophy-iron {
  color: #8a8781;
}

.load-button {
  color: goldenrod;
  background-color: transparent;
  border: 2px solid;
  line-height: 1;
  font-weight: 500;
  border-radius: 1.5rem;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  margin: 0.5rem;
}

.games-loading {
  margin-top: 1rem;
  height: 36px;
}

.details-expand {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-left: 14px;
  margin-top: 0.5rem;
}

.play-info {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

summary {
  user-select: none;
  cursor: pointer;
}

.load-button:hover,
.game-card__button:hover {
  color: darkgoldenrod;
  transform: scale(1.05);
}

.trophy-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.trophy-card {
  padding: 0.5rem 1rem;
  display: flex;
  background-color: rgba(192, 192, 192, 0.18);
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  gap: 0.5rem;
}

.trophy-card .trophy {
  font-size: 1.5rem;
}

.trophy-description {
  font-size: 0.75rem;
  color: darkgoldenrod;
}
