.about-container {
  display: flex;
  flex-direction: column;
  color: goldenrod;
  align-items: center;
  gap: 1rem;
}

.about-section {
  padding: 1rem;
  width: 100%;
}

.title {
  font-size: 2rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
}

figure {
  margin-left: 0;
}

figure img {
  margin-top: 0.5rem;
}

.about-section p {
  margin: 0 0 1rem;
}

.about-footer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  padding: 1rem;
  width: 100%;
}

.play-game-link {
  line-height: 1;
  margin-top: 2rem;
}

kbd {
  background-color: goldenrod;
  color: var(--app-brown);
  padding: 0 2px;
  border-radius: 2px;
  font-size: 0.9rem;
}

.about-link {
  color: goldenrod;
  font-weight: bold;
}

.about-link:hover {
  color: darkgoldenrod;
}

@media screen and (min-width: 768px) {
  .about-footer,
  .about-section {
    max-width: 32rem;
  }
}
