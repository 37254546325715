.main {
  min-height: 100vh;
  background-color: var(--app-brown);
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.notification {
  background-color: var(--app-brown);
  box-shadow: 4px 4px 12px 4px rgba(218, 165, 32, 0.24),
    -4px -4px 12px 4px rgba(218, 165, 32, 0.24);
  display: inline-flex;
  justify-content: center;
  color: goldenrod;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  position: fixed;
  align-self: center;
  align-items: center;
  top: 0.5rem;
  line-height: 1;
  z-index: 10;
  border-radius: 0.5rem;
}

.notification-close {
  font-size: 1.125rem;
  cursor: pointer;
}

.notification a {
  color: goldenrod;
  font-weight: bold;
  transition: 0.2s all ease-in-out;
}

.notification-close:hover,
.notification a:hover {
  color: darkgoldenrod;
}
