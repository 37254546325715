.toolbar {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  color: goldenrod;
}

.logo {
  font-size: 1.5rem;
  font-weight: bolder;
  line-height: 1;
  user-select: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.logo:hover {
  color: darkgoldenrod;
}

.game-number {
  font-weight: 500;
  font-size: 1.125rem;
  margin-left: 0.5rem;
}

.game-number-silver {
  color: silver;
}

.toolbar-icons-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.back-btn,
.toolbar-icon {
  color: goldenrod;
  font-size: 1.75rem;
  padding: 0.25rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.back-btn:hover,
.toolbar-icon:hover {
  color: darkgoldenrod;
  transform: scale(1.05);
}

.icon-wrapper,
.menu {
  display: inline-flex;
  position: relative;
}

.notification-dot {
  width: 8px;
  height: 8px;
  background-color: var(--app-red);
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  border: 1px solid var(--app-brown);
}

.menu ul {
  list-style: none;
  display: none;
  position: absolute;
  right: 0;
  top: 2rem;
  background-color: var(--app-brown);
  width: 10rem;
  box-shadow: 4px 4px 8px 4px rgba(218, 165, 32, 0.18),
    -4px -4px 8px 4px rgba(218, 165, 32, 0.18);
  z-index: 1;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  transition: 0.1s all ease-in;
}

.menu-active ul {
  display: block;
}

.menu ul button {
  padding: 0.75rem 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: goldenrod;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  text-align: left;
  transition: 0.1s all ease-in;
}

.menu ul button:hover {
  background-color: rgba(192, 192, 192, 0.18);
}
