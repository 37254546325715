.board-container {
  display: flex;
  height: calc(100vh - 52px);
  justify-content: center;
  color: goldenrod;
  align-items: center;
  flex-direction: column;
  line-height: 1;
  gap: 1.5rem;
}

.game-item {
  display: flex;
  justify-content: center;
  min-height: 1.75rem;
}

.game-info {
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
}

.alternate-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}

.alternate-layout-info {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-left: 1rem;
  flex-grow: 1;
  max-width: 380px;
}

.alternate-layout-info .status {
  height: auto;
  line-height: normal;
}

.error,
.link {
  font-size: 1.5rem;
  text-align: center;
  padding: 0 1rem;
}

.link-normal,
.link {
  color: goldenrod;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.link-normal {
  font-size: 1.125rem;
  font-weight: bold;
}

.link-normal svg {
  font-size: 1.375rem;
}

.link-normal:hover,
.link:hover {
  color: darkgoldenrod;
}

.score {
  font-size: 1.375rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}

.status {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  height: 1.75rem;
  line-height: 1.75rem;
}

.redo {
  background-color: transparent;
  padding: 0;
  color: goldenrod;
  border: none;
  border-radius: 50%;
  line-height: 1;
  height: 1.75rem;
  padding: 2px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.redo:hover {
  color: darkgoldenrod;
  transform: scale(1.05);
}

@media screen and (min-width: 768px) {
  .score {
    font-size: 1.625rem;
  }
}
