.stats-container {
  display: flex;
  flex-direction: column;
  color: goldenrod;
  padding: 1rem;
  align-items: center;
}

.text--medium {
  font-size: 1.125rem;
}

.text--dark {
  color: darkgoldenrod;
}

.text--bold {
  font-weight: bold;
}

.text--center {
  text-align: center;
}

.solves-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  gap: 1rem;
  width: 100%;
}

.solves-stats-row {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.solves-card,
.stats-card,
.ticker-card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(192, 192, 192, 0.18);
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
}

.solves-card {
  width: 33%;
  position: relative;
}

.solve-stat {
  display: flex;
  align-items: center;
}

.emoji-icon-medium {
  font-size: 1.25rem;
  line-height: 1;
}

.emoji-icon-big {
  font-size: 1.75rem;
  line-height: 1;
}

.multiply {
  color: darkgoldenrod;
}

.multiply-margin {
  margin-left: 0.25rem;
}

.solve-count {
  font-size: 1.25rem;
  margin-left: 0.25rem;
}

.solve-tries {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: darkgoldenrod;
}

.solve-increment {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  font-size: 0.75rem;
  color: gold;
  font-weight: 700;
}

.graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph-plot {
  display: flex;
  gap: 6px;
  height: 8rem;
  align-items: flex-end;
  margin: 1rem 0 0.5rem;
  border-bottom: 1px solid #a4a4a4;
}

.graph-entry {
  width: 5px;
  background-color: #a4a4a4;
  position: relative;
}

.axis-marker {
  position: absolute;
  bottom: -1rem;
  font-size: 10px;
  font-weight: 500;
}

.graph-label {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-top: 0.5rem;
}

.global-stats-text {
  margin-top: 0.5rem;
  text-align: center;
  color: darkgoldenrod;
}

.global-stats-text strong {
  color: goldenrod;
}

.graph-label span {
  margin: 0 0.25rem 0.25rem 0;
}

.highlight {
  background-color: goldenrod !important;
}

.highlight::after {
  position: absolute;
  content: '👇';
  font-size: 10px;
  left: 50%;
  margin-left: -5px;
  top: -16px;
}

.stats-card,
.ticker-card {
  gap: 0.75rem;
  width: 100%;
}

.stats-card {
  padding: 1.5rem;
}

.stats-card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.gap-1_25 {
  gap: 1.25rem;
}

.gap-1_5 {
  gap: 1.5rem;
}

.stats-card-item {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.stats-item-key {
  color: darkgoldenrod;
  width: 50%;
  text-align: end;
}

.stats-item-val {
  font-weight: bold;
  width: 50%;
}

.stats-congrats {
  font-size: 1.125rem;
}

.stats-text {
  background-color: gold;
  color: var(--app-brown);
  font-size: 1rem;
  padding: 1rem 2.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stats-text-content {
  line-height: 1.4;
}

.stats-message {
  text-align: center;
}

.stats-message + .stats-message {
  margin-top: 0.5rem;
}

.btn {
  background-color: goldenrod;
  color: var(--app-brown);
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 1.5rem;
  padding: 0.65rem 2rem;
  transition: 0.2s all ease-in-out;
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 0.75rem;
  cursor: pointer;
}

.btn:hover {
  background-color: darkgoldenrod;
  transform: scale(1.05);
}

.stats-footer {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  width: 100%;
}

.stats-footer a {
  color: goldenrod;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .stats-footer,
  .stats-card,
  .ticker-card,
  .solves-stats-container {
    max-width: 32rem;
  }
}
