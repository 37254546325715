.tile {
  background-color: transparent;
  margin: 4px;
  border: 4px dotted var(--app-red);
  color: goldenrod;
  font-size: 1.625rem;
  font-weight: 700;
  border-radius: 50%;
  padding: 0;
  user-select: none;
  transition: all 0.2s ease-in-out;
  position: relative;
  width: 56px;
  height: 56px;
}

.border-div {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  border: 4px solid goldenrod;
  left: -4px;
  top: -4px;
  border-radius: 50%;
}

.tile span {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
}

.flag--start {
  color: rgb(34, 120, 10) !important;
}

.flag--end {
  color: rgb(158, 5, 23) !important;
}

.tile--done {
  color: var(--app-brown);
  box-shadow: 0px 0px 12px 4px rgba(218, 165, 32, 0.54);
}

.tile--done .border-div {
  border-color: darkgoldenrod;
}

.tile--done span {
  background-image: linear-gradient(
    to left,
    rgb(212, 175, 55),
    rgb(212, 175, 55)
  ) !important;
}

.tile--active span,
.tile--tapped span {
  background-image: linear-gradient(
    to left,
    rgba(144, 227, 154, 0.48),
    rgba(144, 227, 154, 0.48)
  );
}

.tile--active {
  cursor: pointer;
}

.tile--active:focus,
.tile--active:hover {
  transform: scale(1.05);
}

.tile--active:hover:active,
.tile--tapped {
  transform: scale(0.95);
}

.tile--end span {
  background-image: linear-gradient(
    to left,
    rgba(255, 0, 0, 0.48),
    rgba(255, 0, 0, 0.48)
  );
}

.wall-left .border-div {
  border-left-color: transparent;
}

.wall-right .border-div {
  border-right-color: transparent;
}

.wall-bottom .border-div {
  border-bottom-color: transparent;
}

.wall-top .border-div {
  border-top-color: transparent;
}
